// src/components/ReadySection.tsx
import React from "react";
import { Link } from "react-router-dom";
import Image1 from "../../assets/images/GettyImages-1014150852_Edted_Squooshed.jpg";
import Image2 from "../../assets/images/GettyImages-1173740593_Edted_Squooshed.jpg";
import Image3 from "../../assets/images/GettyImages-1363841722_Edted_Squooshed.jpg";

const ReadySection: React.FC = () => {
  return (
    <section className="bg-primary-background py-20 px-8">
      <div className="max-w-6xl mx-auto text-center space-y-10">
        {/* Header */}
        <h2 className="text-4xl md:text-5xl font-semibold text-primary">
          Make Direct Mail Data Your Competitive Edge
        </h2>
        <p className="text-2xl text-gray-700">
          Customers who choose DataStable experience outcomes like:
        </p>

        {/* Benefit Columns */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-16 mt-8">
          {/* Happier Customers */}
          <div className="flex flex-col items-center text-center space-y-6 max-w-xs mx-auto">
            <img
              src={Image1}
              alt="Happier Customers"
              className="w-full h-56 rounded-lg object-cover"
            />
            <h3 className="text-2xl font-semibold text-primary">
              Faster Time to Print
            </h3>
            <p className="text-lg text-gray-700">
              Never miss a marketing opportunity with readily available lists
              and clean data.
            </p>
          </div>

          {/* Better Customer Insights */}
          <div className="flex flex-col items-center text-center space-y-6 max-w-xs mx-auto">
            <img
              src={Image2}
              alt="Better Customer Insights"
              className="w-full h-56 rounded-lg object-cover"
            />
            <h3 className="text-2xl font-semibold text-primary">
              Better Customer Insights
            </h3>
            <p className="text-lg text-gray-700">
              Use engagement reports to gain a better understanding of your
              customers and how to reach them.
            </p>
          </div>

          {/* Time and Cost Savings */}
          <div className="flex flex-col items-center text-center space-y-6 max-w-xs mx-auto">
            <img
              src={Image3}
              alt="Time and Cost Savings"
              className="w-full h-56 rounded-lg object-cover"
            />
            <h3 className="text-2xl font-semibold text-primary">
              Greater Campaign ROI
            </h3>
            <p className="text-lg text-gray-700">
              Create targeted, personalized mailings through pristine data for
              more impactful campaigns.
            </p>
          </div>
        </div>

        {/* Divider */}
        <hr className="border-t-2 border-gray-300 w-full my-16" />

        {/* Call to Action */}
        <div className="space-y-6">
          <h3 className="text-3xl md:text-4xl font-semibold text-primary">
            Let’s Build Your Perfect Direct Mail Database Together
          </h3>
          <p className="text-xl text-gray-700 max-w-2xl mx-auto">
            We know every company has unique direct marketing needs. We’ll work
            with you to understand your goals and priorities and use those to
            build a secure data storehouse to launch top-performing campaigns
            and win more customers.
          </p>

          {/* Let's Talk Button */}
          <Link
            to="/contact"
            className="bg-primary-accent text-white font-semibold py-4 px-10 rounded-lg hover:bg-white hover:text-primary transition-colors inline-block mt-8 text-lg"
          >
            Let's Talk
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ReadySection;
