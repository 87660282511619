import * as React from "react";
import HeroImage from "../../assets/images/Girl-Hero.png";
import GearCheckIcon from "../../assets/images/Gear+Check.png";
import SpeedMailIcon from "../../assets/images/Speed+Mail.png";
import SecureDataIcon from "../../assets/images/Secure+Data.png";
import { Link } from "react-router-dom";

const HeroSection: React.FC = () => {
  return (
    <section className="container mx-auto px-4 py-16 flex flex-col md:flex-row items-center justify-between">
      <div className="md:w-1/2 space-y-6">
        <h1 className="text-4xl md:text-5xl font-semibold text-primary">
          Your Reliable Direct Mail Partner
        </h1>
        <h2 className="text-3xl md:text-3xl text-gray-700">
          Mail With Speed, Confidence, & Accuracy
        </h2>
        <p className="text-gray-700 text-lg">
          Blue Horse Data is a direct mail data processing partner committed to
          helping businesses clean, centralize, and verify their customer data
          to create best-in-class mailings.
        </p>
        <Link
          to="/contact"
          className="px-6 py-3 bg-primary-accent text-white font-semibold rounded-md hover:bg-primary-lightBlue-dark transition duration-300 inline-block"
        >
          Get Started
        </Link>
      </div>
      <div className="relative md:w-1/2 mt-8 md:mt-0">
        <img
          src={HeroImage}
          alt="Girl with direct mail"
          className="w-full h-auto"
        />
        <img
          src={GearCheckIcon}
          alt="Gear and Check Icon"
          className="absolute top-0 left-1/4 w-12 md:w-16"
          style={{ transform: "translateY(-50%)" }}
        />
        <img
          src={SpeedMailIcon}
          alt="Speed and Mail Icon"
          className="absolute right-0 top-1/2 w-12 md:w-16"
          style={{ transform: "translate(50%, -50%)" }}
        />
        <img
          src={SecureDataIcon}
          alt="Secure Data Icon"
          className="absolute bottom-0 left-0 w-12 md:w-16"
          style={{ transform: "translate(-25%, 50%)" }}
        />
      </div>
    </section>
  );
};

export default HeroSection;
