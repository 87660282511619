// src/components/Footer.tsx
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/Blue-Horse-Data_Logo_Primary_Light-Blue-White.png";

const Footer: React.FC = () => {
  return (
    <footer className="bg-primary text-white py-12 px-8">
      <div className="max-w-6xl mx-auto text-center md:text-left">
        {/* Logo */}
        <Link to="/">
          <div className="flex justify-center md:justify-start mb-6">
            <img src={Logo} alt="Blue Horse Data Logo" className="h-12" />
          </div>
        </Link>

        {/* Description */}
        <p className="text-sm md:text-base max-w-lg mx-auto md:mx-0 mb-8">
          Blue Horse Data is the reliable direct mail partner companies,
          agencies, and printers trust to help them mail with speed, confidence,
          and accuracy. Our goal is to empower businesses to better connect with
          customers through clean and accurate direct mail data.
        </p>

        {/* Links */}
        <div className="flex flex-col md:flex-row md:justify-between items-center md:items-start space-y-4 md:space-y-0 text-sm">
          <div className="space-x-4 mb-4 md:mb-0">
            <Link to="/" className="hover:underline">
              Home
            </Link>
            <Link to="/contact" className="hover:underline">
              Contact
            </Link>
            <Link to="/services/truemerge" className="hover:underline">
              TrueMerge
            </Link>
            <Link to="/services/rapidpost" className="hover:underline">
              RapidPost
            </Link>
            <Link to="/services/datastable" className="hover:underline">
              DataStable
            </Link>
          </div>

          {/* Copyright */}
          <div>
            <p>© 2024 Blue Horse Data. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
