// src/components/ResourceWasteSection.tsx
import * as React from "react";

const ResourceWasteSection: React.FC = () => {
  return (
    <section className="container mx-auto px-4 py-16 space-y-8 md:space-y-0 md:flex md:items-start md:justify-between">
      {/* Left Column */}
      <div className="md:w-1/2 space-y-4">
        <h2 className="text-5xl text-primary">
          Are You Wasting Resources On Inaccurate Mailings?
        </h2>
        <p className="text-gray-700 text-lg">
          When done right, direct mail campaigns can attract new customers,
          strengthen existing relationships, and build revenue and brand
          recognition.
        </p>
      </div>

      {/* Divider for mobile view */}
      <hr className="md:hidden my-8 border-gray-300" />

      {/* Right Column */}
      <div className="md:w-1/2 space-y-4">
        <h3 className="text-2xl font-semibold text-gray-500">
          If not handled carefully, mailing efforts can also:
        </h3>
        <ul className="space-y-2">
          <li className="flex items-start text-lg text-gray-800">
            <span className="mr-2 text-2xl text-red-600">x</span>{" "}
            <span>Drain staff time & resources</span>
          </li>
          <li className="flex items-start text-lg text-gray-800">
            <span className="mr-2 text-2xl text-red-600">x</span>{" "}
            <span>Drive away customers</span>
          </li>
          <li className="flex items-start text-lg text-gray-800">
            <span className="mr-2 text-2xl text-red-600">x</span>{" "}
            <span>Result in excess postal & print costs</span>
          </li>
        </ul>
        <p className="text-gray-700 text-lg">
          You need a partner you can trust with your direct mail data cleansing
          to make sure your marketing dollars and efforts are building customer
          relationships, not wasting resources.
        </p>
      </div>
    </section>
  );
};

export default ResourceWasteSection;
