// src/views/home/TestimonialsSection.tsx
import React, { useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group"; // For transitions

const testimonials = [
  {
    quote:
      "The team at Blue Horse Data and their DataStable product have been a key part of our direct marketing program for years. Their expertise, responsiveness, and thought leadership are invaluable to us as we continue to grow and scale our business.",
    author: "VP of Marketing, Global Luxury Travel Company",
  },
  {
    quote:
      "I have been very impressed with RapidPost and the team at Blue Horse Data. The processing speed and ease of use make RapidPost a powerful solution for CASS/NCOA processing.",
    author: "CEO, Premier Data Operations Workflow Automation Platform",
  },
  {
    quote:
      "TrueMerge and the team at Blue Horse Data have been invaluable to our mail program. I am continually impressed by their speed and attention to detail in managing eight plus mailings per month for us across six brands.",
    author:
      "Associate Director of Offline Media, Major Subscription Meal Kit Company",
  },
];

// Custom Left Arrow SVG with lighter blue background
const LeftArrowIcon: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <div
    onClick={onClick}
    className="absolute left-6 md:left-12 top-1/2 transform -translate-y-1/2 bg-primary-background hover:bg-primary-lightBlue p-4 rounded-full cursor-pointer transition-colors"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="white"
      className="w-8 h-8"
    >
      <path d="M15.5 3.5L9 12l6.5 8.5" stroke="currentColor" strokeWidth="2" />
    </svg>
  </div>
);

// Custom Right Arrow SVG with lighter blue background
const RightArrowIcon: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <div
    onClick={onClick}
    className="absolute right-6 md:right-12 top-1/2 transform -translate-y-1/2 bg-primary-background hover:bg-primary-lightBlue p-4 rounded-full cursor-pointer transition-colors"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="white"
      className="w-8 h-8"
    >
      <path d="M8.5 3.5L15 12l-6.5 8.5" stroke="currentColor" strokeWidth="2" />
    </svg>
  </div>
);

const TestimonialsSection: React.FC = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const handlePrevious = () => {
    setCurrentTestimonial((prev) =>
      prev === 0 ? testimonials.length - 1 : prev - 1
    );
  };

  const handleNext = () => {
    setCurrentTestimonial((prev) =>
      prev === testimonials.length - 1 ? 0 : prev + 1
    );
  };

  return (
    <section className="bg-primary-background py-24 px-12 relative">
      <div className="max-w-4xl mx-auto text-center">
        {/* Testimonial with Transition */}
        <SwitchTransition>
          <CSSTransition
            key={currentTestimonial}
            timeout={400}
            classNames="fade"
          >
            <div>
              <p className="text-primary-lightBlue text-2xl md:text-3xl italic font-semibold mb-6 transition-opacity duration-400 leading-relaxed">
                "{testimonials[currentTestimonial].quote}"
              </p>
              <p className="text-black font-medium text-lg">
                {testimonials[currentTestimonial].author}
              </p>
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>

      {/* Navigation Arrows */}
      <LeftArrowIcon onClick={handlePrevious} />
      <RightArrowIcon onClick={handleNext} />
    </section>
  );
};

export default TestimonialsSection;
