// src/components/QuoteSection.tsx
import React from "react";

const QuoteSection: React.FC = () => {
  return (
    <section className="bg-blue-200 py-16 px-8">
      <div className="max-w-4xl mx-auto text-center">
        {/* Quote */}
        <h2 className="text-2xl md:text-3xl font-semibold text-primary-lightBlue mb-6">
          "I have been very impressed with RapidPost and the team at Blue Horse
          Data. The processing speed and ease of use make RapidPost a powerful
          solution for CASS/NCOA processing."
        </h2>

        {/* Attribution */}
        <p className="text-gray-700 text-lg">
          - CEO, Premier Data Operations Workflow Automation Platform
        </p>
      </div>
    </section>
  );
};

export default QuoteSection;
