import * as React from "react";

const ReasonSection: React.FC = () => {
  return (
    <section className="container mx-auto px-16 py-16 space-y-8 md:space-y-0 md:flex md:items-start md:justify-between md:gap-x-16">
      {/* Left Column */}
      <div className="md:w-2/5 space-y-4">
        <h2 className="text-5xl text-primary">
          Scattered Mailing Data = Missed Revenue Opportunities
        </h2>
        <p className="text-gray-700 text-lg">
          When it comes to direct mail marketing, most companies and
          organizations find their customer data scattered across numerous
          locations and in varying formats. This creates a mountain of data
          cleansing work in preparation for each new mailing, and it can also
          result in other issues.
        </p>
      </div>

      {/* Divider for mobile view */}
      <hr className="md:hidden my-8 border-gray-300" />

      {/* Right Column */}
      <div className="md:w-1/2 space-y-4">
        <h3 className="text-2xl font-semibold text-gray-500">
          Challenges that messy customer mailing data creates:
        </h3>
        <ul className="space-y-2">
          <li className="flex items-start text-lg text-gray-800">
            <span className="mr-2 text-2xl text-red-600">x</span>
            <span>Misguided campaign strategies</span>
          </li>
          <li className="flex items-start text-lg text-gray-800">
            <span className="mr-2 text-2xl text-red-600">x</span>
            <span>Wasted staff time & energy</span>
          </li>
          <li className="flex items-start text-lg text-gray-800">
            <span className="mr-2 text-2xl text-red-600">x</span>
            <span>Poor reporting & performance visibility</span>
          </li>
          <li className="flex items-start text-lg text-gray-800">
            <span className="mr-2 text-2xl text-red-600">x</span>
            <span>Slow speed to delivery on campaigns</span>
          </li>
          <li className="flex items-start text-lg text-gray-800">
            <span className="mr-2 text-2xl text-red-600">x</span>
            <span>Missed marketing opportunities</span>
          </li>
        </ul>
        <p className="text-gray-700 text-lg">
          But that doesn’t have to be your reality.
        </p>
      </div>
    </section>
  );
};

export default ReasonSection;
