import React from "react";
import ProductTitleSection from "./ProductTitleSection";
import ReasonSection from "./ReasonSection";
import ImageSection from "./ImageSection";
import DataStableSection from "./DataStableSection";
import DataStableKeyFeaturesSection from "./DataStableKeyFeaturesSection";
import QuoteSection from "./QuoteSection";
import ReadySection from "./ReadySection";

const DataStable: React.FC = () => {
  return (
    <div className="">
      <ProductTitleSection />
      <ReasonSection />
      <ImageSection />
      <DataStableSection />
      <DataStableKeyFeaturesSection />
      <QuoteSection />
      <ReadySection />
    </div>
  );
};

export default DataStable;
