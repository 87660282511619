// src/components/AddressValidationSection.tsx
import * as React from "react";
import BackgroundImage from "../../assets/images/GettyImages-1489414357_Edited_Squooshed.jpg";
import Icon1 from "../../assets/images/Blue-Horse-Data_RapidPost_Icon-1.png";
import Icon2 from "../../assets/images/Blue-Horse-Data_RapidPost_Icon-2.png";
import Icon3 from "../../assets/images/Blue-Horse-Data_RapidPost_Icon-3.png";

const AddressValidationSection: React.FC = () => {
  return (
    <section className="relative">
      {/* Full-width Image */}
      <div
        className="w-full h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      />

      {/* White Background Part */}
      <div className="bg-white pt-24 pb-16 px-8 md:px-16 lg:px-32 flex flex-col md:flex-row gap-8 md:gap-20">
        {/* Large Overlapping Rectangle */}
        <div className="relative md:w-2/3 lg:w-1/2 bg-primary text-white p-8 md:p-10 rounded-lg -mt-44 md:ml-8 lg:ml-16 shadow-lg z-10">
          <h2 className="text-4xl md:text-5xl font-semibold mb-6">
            Take Your Address Validation Process From 1
          </h2>
          <hr className="border-t-2 border-primary-accent w-full my-6" />
          <p className="text-xl md:text-2xl leading-relaxed">
            RapidPost, our custom-built USPS Address Validation API, helps
            printers and businesses eliminate the unnecessary internal back and
            forth of updating tables and validating addresses. With just a few
            clicks, your team can upload mailing records for CASS/NCOA
            processing and receive mail-ready records back in minutes in your
            preferred format.
          </p>
        </div>

        {/* Right Side with 3 Rows */}
        <div className="space-y-12 mt-8 md:mt-0 md:w-1/2">
          {/* Row 1: Fast Processing */}
          <div className="flex items-start text-gray-800 space-x-4">
            <img src={Icon1} alt="Fast Processing Icon" className="w-16 h-16" />
            <div className="space-y-2">
              <h3 className="text-xl font-semibold text-primary">
                Fast Processing
              </h3>
              <p className="text-lg">
                6,000+ records processed per second reduces wait time
              </p>
            </div>
          </div>

          {/* Row 2: Easy Integration */}
          <div className="flex items-start text-gray-800 space-x-4">
            <img
              src={Icon2}
              alt="Easy Integration Icon"
              className="w-16 h-16"
            />
            <div className="space-y-2">
              <h3 className="text-xl font-semibold text-primary">
                Easy Integration
              </h3>
              <p className="text-lg">
                RESTful API works seamlessly with any system
              </p>
            </div>
          </div>

          {/* Row 3: Top Security */}
          <div className="flex items-start text-gray-800 space-x-4">
            <img src={Icon3} alt="Top Security Icon" className="w-16 h-16" />
            <div className="space-y-2">
              <h3 className="text-xl font-semibold text-primary">
                Top Security
              </h3>
              <p className="text-lg">
                Software meets CASS Certification & SOC 2 Compliance
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddressValidationSection;
