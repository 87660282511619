// src/views/home/TrustedBySection.tsx
import React from "react";
import TruckIcon from "../../assets/images/Truck-Icon.png";
import TravelIcon from "../../assets/images/Tracel-Icon.png";
import DesignIcon from "../../assets/images/Design-Icon.png";
import AdventureIcon from "../../assets/images/Adventure-Icon.png";
import MailboxIcon from "../../assets/images/Mailbox-Icon.png";

const TrustedBySection: React.FC = () => {
  return (
    <section className="bg-gray-200 py-20">
      <div className="text-center mb-16">
        <h2 className="text-3xl font-semibold text-primary mb-8">
          Trusted By Clients Including:
        </h2>
        <hr className="border-t border-gray-400 w-1/4 mx-auto mb-12" />
      </div>

      {/* Icons and Descriptions in Responsive Grid Layout */}
      <div className="max-w-7xl mx-auto px-8 grid grid-cols-1 md:grid-cols-5 gap-12">
        {/* Client 1 */}
        <div className="text-center flex flex-col items-center space-y-8">
          <img src={TruckIcon} alt="Truck Icon" className="w-24 h-auto" />
          <p className="text-lg font-medium text-gray-800">
            Top U.S. Meal Subscription Providers
          </p>
        </div>

        {/* Client 2 */}
        <div className="text-center flex flex-col items-center space-y-8">
          <img src={TravelIcon} alt="Travel Icon" className="w-24 h-auto" />
          <p className="text-lg font-medium text-gray-800">
            Global Travel & Tourism Companies
          </p>
        </div>

        {/* Client 3 */}
        <div className="text-center flex flex-col items-center space-y-8">
          <img src={DesignIcon} alt="Design Icon" className="w-24 h-auto" />
          <p className="text-lg font-medium text-gray-800">
            Leading Design & Decor Companies
          </p>
        </div>

        {/* Client 4 */}
        <div className="text-center flex flex-col items-center space-y-8">
          <img
            src={AdventureIcon}
            alt="Adventure Icon"
            className="w-24 h-auto"
          />
          <p className="text-lg font-medium text-gray-800">
            Adventure Sports & Leisure Companies
          </p>
        </div>

        {/* Client 5 */}
        <div className="text-center flex flex-col items-center space-y-8">
          <img src={MailboxIcon} alt="Mailbox Icon" className="w-24 h-auto" />
          <p className="text-lg font-medium text-gray-800">
            Marketing & Direct Mail Agencies
          </p>
        </div>
      </div>
    </section>
  );
};

export default TrustedBySection;
