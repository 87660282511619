import React from "react";
import ProductTitleSection from "./ProductTitleSection";
import ReasonSection from "./ReasonSection";
import AddressValidationSection from "./AddressValidationSection";
import HowRapidPostWorksSection from "./HowRapidPostWorksSection";
import QuoteSection from "./QuoteSection";
import CustomerBenefitsSection from "./CustomerBenefitsSection";

const RapidPost: React.FC = () => {
  return (
    <div className="">
      <ProductTitleSection />
      <ReasonSection />
      <AddressValidationSection />
      <HowRapidPostWorksSection />
      <QuoteSection />
      <CustomerBenefitsSection />
    </div>
  );
};

export default RapidPost;
