import * as React from "react";
import { Link } from "react-router-dom";
import BackgroundImage from "../../assets/images/Services+Header+Background.jpg";
import TrueMergeLogo from "../../assets/images/Blue-Horse-Data_RapidPost_Logo.png";

const ProductTitleSection: React.FC = () => {
  return (
    <section
      className="relative bg-cover bg-center h-[40vh] flex items-center justify-center"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <div className="text-center space-y-6">
        <img
          src={TrueMergeLogo}
          alt="Blue Horse Data TrueMerge Logo"
          className="w-64 md:w-80 mx-auto"
        />

        <h2 className="text-2xl md:text-3xl text-primary">
          Mail With Precision Using CASS™/NCOA via API
        </h2>

        <Link
          to="/contact"
          className="inline-block mt-6 px-8 py-3 bg-primary-accent text-white font-semibold rounded-md hover:bg-primary-lightBlue-dark transition duration-300"
        >
          Get Started
        </Link>
      </div>
    </section>
  );
};

export default ProductTitleSection;
