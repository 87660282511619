import React from "react";
import ProductTitleSection from "./ProductTitleSection";
import ResourceWasteSection from "./ResourceWasteSection";
import SpotlessMailingSection from "./SpotlessMailingSection";
import ExperienceSection from "./ExperienceSection";
import HowTrueMergeWorksSection from "./HowTrueMergeWorksSection";
import QuoteSection from "./QuoteSection";
import TrueMergeBenefitsSection from "./TrueMergeBenefitsSection";

const TrueMerge: React.FC = () => {
  return (
    <div className="">
      <ProductTitleSection />
      <ResourceWasteSection />
      <SpotlessMailingSection />
      <ExperienceSection />
      <HowTrueMergeWorksSection />
      <QuoteSection />
      <TrueMergeBenefitsSection />
    </div>
  );
};

export default TrueMerge;
