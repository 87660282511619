import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./views/home/Home";
import TrueMerge from "./views/truemerge/TrueMerge";
import RapidPost from "./views/rapidpost/RapidPost";
import DataStable from "./views/datastable/DataStable";
import Contact from "./views/contact/Contact";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

const App: React.FC = () => {
  return (
    <Router>
      <Header />
      <main className="">
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services/truemerge" element={<TrueMerge />} />
          <Route path="/services/rapidpost" element={<RapidPost />} />
          <Route path="/services/datastable" element={<DataStable />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
