// src/components/HowTrueMergeWorksSection.tsx
import React from "react";
import Icon1 from "../../assets/images/TrueMerge_Works_Icon_1.png";
import Icon2 from "../../assets/images/TrueMerge_Works_Icon_3.png";
import Icon3 from "../../assets/images/TrueMerge_Works_Icon_4.png";
import Icon4 from "../../assets/images/TrueMerge_Works_Icon_6.png";
import Icon5 from "../../assets/images/Frame+557.png";

const HowTrueMergeWorksSection: React.FC = () => {
  return (
    <section className="bg-primary text-white py-16 px-8">
      {/* Title and HR */}
      <div className="text-center mb-12">
        <h2 className="text-3xl md:text-4xl font-semibold mb-8">
          How TrueMerge Works
        </h2>
        <hr className="border-t-1 border-primary-lightBlue w-64 mx-auto" />
      </div>

      {/* Steps Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12 max-w-5xl mx-auto mb-12">
        {/* Step 1 */}
        <div className="flex flex-col items-center text-center space-y-4 max-w-sm mx-auto">
          <img
            src={Icon1}
            alt="Send Us Your List of Lists"
            className="w-20 h-20"
          />
          <h3 className="text-xl font-semibold">Send Us Your List of Lists</h3>
          <p className="text-gray-200">
            Gather your mailing lists — no need to clean them up first — along
            with a list of your priorities and formatting preferences.
          </p>
        </div>

        {/* Step 2 */}
        <div className="flex flex-col items-center text-center space-y-4 max-w-sm mx-auto">
          <img
            src={Icon2}
            alt="Tidy Up & Ditch the Duplicates"
            className="w-20 h-20"
          />
          <h3 className="text-xl font-semibold">
            Tidy Up & Ditch the Duplicates
          </h3>
          <p className="text-gray-200">
            We’ll organize the data to make sure it's consistent, fix any
            mistakes, reduce redundancies, and flag potential duplicates for
            your final sign off.
          </p>
        </div>

        {/* Step 3 */}
        <div className="flex flex-col items-center text-center space-y-4 max-w-sm mx-auto">
          <img src={Icon3} alt="Make Your Selects" className="w-20 h-20" />
          <h3 className="text-xl font-semibold">Make Your Selects</h3>
          <p className="text-gray-200">
            Next, we’ll provide you with custom reports (multis, hotlines,
            uniques, inactives, and more) so that you can make selections of
            what to include in or exclude from your final mailing lists.
          </p>
        </div>
      </div>

      {/* Additional Steps Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-5xl mx-auto">
        {/* Step 4 */}
        <div className="flex flex-col items-center text-center space-y-4 max-w-sm mx-auto">
          <img
            src={Icon4}
            alt="Prepare for Perfect Mailings"
            className="w-20 h-20"
          />
          <h3 className="text-xl font-semibold">
            Prepare for Perfect Mailings
          </h3>
          <p className="text-gray-200">
            Then, we’ll execute on those selections, make sure records match
            your criteria, and deliver clean and consolidated mailing lists that
            you can use to launch best-in-class mail campaigns.
          </p>
        </div>

        {/* Step 5 */}
        <div className="flex flex-col items-center text-center space-y-4 max-w-sm mx-auto">
          <img src={Icon5} alt="See Your ROI" className="w-20 h-20" />
          <h3 className="text-xl font-semibold">See Your ROI</h3>
          <p className="text-gray-200">
            Even after your mail reaches customers, we’re here to support you
            through matchback reporting. Send us new customer transactions along
            with your mailing list, and we’ll calculate the ROI and key
            performance metrics to measure campaign success.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HowTrueMergeWorksSection;
