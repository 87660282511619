import * as React from "react";

const ReasonSection: React.FC = () => {
  return (
    <section className="container mx-auto px-16 py-16 space-y-8 md:space-y-0 md:flex md:items-start md:justify-between md:gap-x-16">
      {/* Left Column */}
      <div className="md:w-2/5 space-y-4">
        <h2 className="text-5xl text-primary">
          USPS® CASS/NCOA Processing Can Be Tedious & Time Consuming
        </h2>
        <p className="text-gray-700 text-lg">
          Processing mailing records through the Coding Accuracy Support System
          (CASS) and National Change of Address (NCOA) might be just one task on
          your never-ending to-do list. Yet that one task can take hours, and
          sometimes days, without the right software in place.
        </p>
      </div>

      {/* Divider for mobile view */}
      <hr className="md:hidden my-8 border-gray-300" />

      {/* Right Column */}
      <div className="md:w-1/2 space-y-4">
        <h3 className="text-2xl font-semibold text-gray-500">
          Businesses are losing valuable time and money due to:
        </h3>
        <ul className="space-y-2">
          <li className="flex items-start text-lg text-gray-800">
            <span className="mr-2 text-2xl text-red-600">x</span>
            <span>Constantly updating USPS tables</span>
          </li>
          <li className="flex items-start text-lg text-gray-800">
            <span className="mr-2 text-2xl text-red-600">x</span>
            <span>Long wait times between processing tasks</span>
          </li>
          <li className="flex items-start text-lg text-gray-800">
            <span className="mr-2 text-2xl text-red-600">x</span>
            <span>
              Transitioning serviced records back into preferred formats
            </span>
          </li>
        </ul>
        <p className="text-gray-700 text-lg">
          But that doesn’t have to be your reality.
        </p>
      </div>
    </section>
  );
};

export default ReasonSection;
