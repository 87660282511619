import React from "react";
import HeroSection from "./HeroSection";
import TrustedBySection from "./TrustedBySection";
import DirectMailChallengesSection from "./DirectMailChallenges";
import TakeBackReinsSection from "./TakeBackReinsSection";
import OurServicesSection from "./OurServicesSection";
import TestimonialsSection from "./TestimonialsSection";
import ExpectationsSection from "./ExpectationsSection";
import SafeDataSection from "./SafeDataSection";
import ContactSection from "./ContactSection";

const Home: React.FC = () => {
  return (
    <div>
      <HeroSection />
      <TrustedBySection />
      <DirectMailChallengesSection />
      <TakeBackReinsSection />
      <OurServicesSection />
      <TestimonialsSection />
      <ExpectationsSection />
      <SafeDataSection />
      <ContactSection />
    </div>
  );
};

export default Home;
