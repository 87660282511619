// src/components/TrueMergeBenefitsSection.tsx
import React from "react";
import { Link } from "react-router-dom";
import Image1 from "../../assets/images/GettyImages-1147289338_Squooshed.jpg";
import Image2 from "../../assets/images/GettyImages-1522426345_Squooshed.jpg";
import Image3 from "../../assets/images/GettyImages-1434116614_Squooshed.jpg";

const TrueMergeBenefitsSection: React.FC = () => {
  return (
    <section className="bg-primary-background py-20 px-8">
      <div className="max-w-6xl mx-auto text-center space-y-10">
        {/* Header */}
        <h2 className="text-4xl md:text-5xl font-semibold text-primary">
          Enhance Your Mailings With TrueMerge
        </h2>
        <p className="text-2xl text-gray-700">
          Customers who choose TrueMerge experience outcomes like:
        </p>

        {/* Benefit Columns */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-16 mt-8">
          {/* Happier Customers */}
          <div className="flex flex-col items-center text-center space-y-6 max-w-xs mx-auto">
            <img
              src={Image1}
              alt="Happier Customers"
              className="w-full h-56 rounded-lg object-cover"
            />
            <h3 className="text-2xl font-semibold text-primary">
              Happier Customers
            </h3>
            <p className="text-lg text-gray-700">
              Improve customer satisfaction with targeted, error-free mailings.
            </p>
          </div>

          {/* Higher Performing Campaigns */}
          <div className="flex flex-col items-center text-center space-y-6 max-w-xs mx-auto">
            <img
              src={Image2}
              alt="Higher Performing Campaigns"
              className="w-full h-56 rounded-lg object-cover"
            />
            <h3 className="text-2xl font-semibold text-primary">
              Higher Performing Campaigns
            </h3>
            <p className="text-lg text-gray-700">
              Execute campaigns faster for better results and refine your
              strategy with matchback insights.
            </p>
          </div>

          {/* Time and Cost Savings */}
          <div className="flex flex-col items-center text-center space-y-6 max-w-xs mx-auto">
            <img
              src={Image3}
              alt="Time and Cost Savings"
              className="w-full h-56 rounded-lg object-cover"
            />
            <h3 className="text-2xl font-semibold text-primary">
              Time and Cost Savings
            </h3>
            <p className="text-lg text-gray-700">
              Reduce wasted marketing spend with more accurate customer mailing
              lists.
            </p>
          </div>
        </div>

        {/* Divider */}
        <hr className="border-t-2 border-gray-300 w-full my-16" />

        {/* Call to Action */}
        <div className="space-y-6">
          <h3 className="text-3xl md:text-4xl font-semibold text-primary">
            Ready To Get Started With TrueMerge?
          </h3>
          <p className="text-xl text-gray-700 max-w-2xl mx-auto">
            Shoot us an email or set up a call with a member of our team today
            to learn more about how we can help you accelerate your merge/purge
            process with TrueMerge.
          </p>

          {/* Let's Talk Button */}
          <Link
            to="/contact"
            className="bg-primary-accent text-white font-semibold py-4 px-10 rounded-lg hover:bg-white hover:text-primary transition-colors inline-block mt-8 text-lg"
          >
            Let's Talk
          </Link>
        </div>
      </div>
    </section>
  );
};

export default TrueMergeBenefitsSection;
