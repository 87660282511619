// src/components/DataStableKeyFeaturesSection.tsx
import React from "react";
import Icon1 from "../../assets/images/DataStable_Works_Icon_1.png";
import Icon2 from "../../assets/images/DataStable_Works_Icon_2.png";
import Icon3 from "../../assets/images/DataStable_Works_Icon_3.png";
import Icon4 from "../../assets/images/DataStable_Works_Icon_4.png";
import Icon5 from "../../assets/images/DataStable_Works_Icon_5.png";
import Icon6 from "../../assets/images/DataStable_Works_Icon_6.png";
import Icon7 from "../../assets/images/DataStable_Works_Icon_7.png";

const DataStableKeyFeaturesSection: React.FC = () => {
  return (
    <section className="bg-primary text-white py-24 px-8 md:px-16 lg:px-32">
      <div className="max-w-7xl mx-auto text-center space-y-12">
        {/* Section Title */}
        <h2 className="text-4xl md:text-5xl font-bold">
          DataStable Key Features
        </h2>
        <p className="text-xl max-w-3xl mx-auto">
          Here’s what you can expect from DataStable, your direct mail marketing
          solution:
        </p>
        <hr className="border-t-1 border-primary-lightBlue w-2/3 mx-auto my-8" />

        {/* Feature Boxes */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Box 1 */}
          <div className="bg-primary border-2 border-primary-lightBlue rounded-3xl p-8 text-center space-y-4">
            <img
              src={Icon1}
              alt="One source of truth icon"
              className="w-16 h-16 mx-auto"
            />
            <p>
              <span className="font-bold">One source of truth</span> for all of
              your customer mailing data
            </p>
          </div>

          {/* Box 2 */}
          <div className="bg-primary border-2 border-primary-lightBlue rounded-3xl p-8 text-center space-y-4">
            <img
              src={Icon2}
              alt="Customer account consolidation icon"
              className="w-16 h-16 mx-auto"
            />
            <p>
              <span className="font-bold">Customer account consolidation</span>{" "}
              to keep your data duplicate free
            </p>
          </div>

          {/* Box 3 */}
          <div className="bg-primary border-2 border-primary-lightBlue rounded-3xl p-8 text-center space-y-4">
            <img
              src={Icon3}
              alt="Mail engagement history icon"
              className="w-16 h-16 mx-auto"
            />
            <p>
              <span className="font-bold">Mail engagement history</span> for
              better insights into customers
            </p>
          </div>

          {/* Box 4 */}
          <div className="bg-primary border-2 border-primary-lightBlue rounded-3xl p-8 text-center space-y-4">
            <img
              src={Icon4}
              alt="Audience segmentation icon"
              className="w-16 h-16 mx-auto"
            />
            <p>
              <span className="font-bold">Audience segmentation</span> for more
              effective, targeted campaigns
            </p>
          </div>

          {/* Box 5 */}
          <div className="bg-primary border-2 border-primary-lightBlue rounded-3xl p-8 text-center space-y-4">
            <img
              src={Icon5}
              alt="Direct mail reports icon"
              className="w-16 h-16 mx-auto"
            />
            <p>
              <span className="font-bold">Direct mail reports</span> to track
              your efforts and progress toward your goals
            </p>
          </div>

          {/* Box 6 */}
          <div className="bg-primary border-2 border-primary-lightBlue rounded-3xl p-8 text-center space-y-4">
            <img
              src={Icon6}
              alt="Mail merge & matchback support icon"
              className="w-16 h-16 mx-auto"
            />
            <p>
              <span className="font-bold">Mail merge & matchback support</span>{" "}
              to keep your marketing mailing lists clean and mail ready
            </p>
          </div>

          {/* Box 7 */}
          <div className="bg-primary border-2 border-primary-lightBlue rounded-3xl p-8 text-center space-y-4 md:col-span-2">
            <img
              src={Icon7}
              alt="Built-in specialist support icon"
              className="w-16 h-16 mx-auto"
            />
            <p>
              <span className="font-bold">Built-in specialist support</span> for
              special requests or troubleshooting
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DataStableKeyFeaturesSection;
