import * as React from "react";
import ArrowIcon from "../../assets/images/Blue-Horse-Data_Down-Arrow_Blue-2.png";

const HowRapidPostWorksSection: React.FC = () => {
  return (
    <section className="bg-primary text-white py-16 px-8">
      <div className="max-w-4xl mx-auto text-center space-y-12">
        {/* Section Heading */}
        <h2 className="text-3xl md:text-4xl font-semibold">
          How RapidPost Works
        </h2>

        {/* Step 1 */}
        <div className="space-y-8">
          <img src={ArrowIcon} alt="Down Arrow" className="mx-auto w-12 h-12" />
          <div className="text-center">
            <h3 className="text-2xl font-semibold">
              Get Your Custom Access Code
            </h3>
            <p className="text-lg mt-2 max-w-lg mx-auto">
              We’ll send you a unique and secure URL, client ID, and API key to
              use whenever you want to upload records to RapidPost and
              seamlessly integrate into your current process.
            </p>
          </div>
        </div>

        {/* Step 2 */}
        <div className="space-y-8">
          <img src={ArrowIcon} alt="Down Arrow" className="mx-auto w-12 h-12" />
          <div className="text-center">
            <h3 className="text-2xl font-semibold">
              Upload Your Mailing Records
            </h3>
            <p className="text-lg mt-2 max-w-lg mx-auto">
              With the click of a button, upload CSV files of individual records
              or billions of records at a time — all in your preferred format.
            </p>
          </div>
        </div>

        {/* Step 3 */}
        <div className="space-y-8">
          <img src={ArrowIcon} alt="Down Arrow" className="mx-auto w-12 h-12" />
          <div className="text-center">
            <h3 className="text-2xl font-semibold">
              Sit Back & Watch the CASS/NCOA Magic
            </h3>
            <p className="text-lg mt-2 max-w-lg mx-auto">
              We’ll take your list and process it through our always-up-to-date
              CASS/NCOA database at the rapid speed of 6,000+ records per
              second.
            </p>
          </div>
        </div>

        {/* Step 4 */}
        <div className="space-y-8">
          <img src={ArrowIcon} alt="Down Arrow" className="mx-auto w-12 h-12" />
          <div className="text-center">
            <h3 className="text-2xl font-semibold">
              Enjoy Mail-Ready Records in Minutes
            </h3>
            <p className="text-lg mt-2 max-w-lg mx-auto">
              We’ll send back your CASS-/NCOA-standardized records in your own
              format along with your original file, additional data like address
              type and delivery point, and a Processing Summary Report and 3553
              CASS Summary Report.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowRapidPostWorksSection;
