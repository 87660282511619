// src/components/DataStableSection.tsx
import React from "react";
import Logo from "../../assets/images/AICPA_SOC_Badge_Light-Background.png";
import Icon1 from "../../assets/images/DataStable+Body+Icon_1.png";
import Icon2 from "../../assets/images/DataStable+Body+Icon_2+(2).png";
import Icon3 from "../../assets/images/DataStable+Body+Icon_3.png";

const DataStableSection: React.FC = () => {
  return (
    <section className="bg-gray-50 py-20 px-8 md:px-16 lg:px-32">
      <div className="max-w-6xl mx-auto space-y-16">
        {/* First Row */}
        <div className="flex flex-col md:flex-row md:space-x-8">
          {/* Left Column (1/3) */}
          <div className="md:w-2/3 space-y-6">
            <p className="font-bold text-lg text-primary">
              That’s why we created DataStable — your secure direct marketing
              storehouse for maintaining and cleansing current customer and
              direct mail data.
            </p>
            <p className="text-gray-700">
              Your database is managed and maintained daily by our team of
              direct mail specialists and data scientists focused on improving
              the quality and efficiency of your direct marketing efforts.
            </p>
          </div>

          {/* Right Column (2/3) */}
          <div className="flex justify-center md:justify-start md:w-1/3 mt-8 md:mt-0">
            <img
              src={Logo}
              alt="AICPA SOC Badge"
              className="w-32 h-32 md:w-48 md:h-48 object-contain"
            />
          </div>
        </div>

        {/* Section Title */}
        <h2 className="text-3xl md:text-4xl font-semibold text-center text-primary mt-16">
          How It Works:
        </h2>

        {/* Second Row with 3 Columns */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mt-12 text-center">
          {/* Column 1 */}
          <div className="flex flex-col items-center space-y-4">
            <img src={Icon1} alt="Step 1 Icon" className="w-16 h-16" />
            <hr className="border-t-2 border-gray-300 w-full max-w-[80px]" />
            <h3 className="text-xl font-semibold text-primary">Step 1</h3>
            <p className="text-gray-700">
              Upload new customer data, direct mail lists, and transactions as
              they come.
            </p>
          </div>

          {/* Column 2 */}
          <div className="flex flex-col items-center space-y-4">
            <img src={Icon2} alt="Step 2 Icon" className="w-16 h-16" />
            <hr className="border-t-2 border-gray-300 w-full max-w-[80px]" />
            <h3 className="text-xl font-semibold text-primary">Step 2</h3>
            <p className="text-gray-700">
              Data is automatically cleansed and standardized in your database
              based on your preferences.
            </p>
          </div>

          {/* Column 3 */}
          <div className="flex flex-col items-center space-y-4">
            <img src={Icon3} alt="Step 3 Icon" className="w-16 h-16" />
            <hr className="border-t-2 border-gray-300 w-full max-w-[80px]" />
            <h3 className="text-xl font-semibold text-primary">Step 3</h3>
            <p className="text-gray-700">
              Receive mail-ready files, segmented customer mailing lists,
              performance reports, and detailed records the moment you need
              them.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DataStableSection;
