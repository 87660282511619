// src/components/ExperienceSection.tsx
import React from "react";
import Icon1 from "../../assets/images/True+Merge+Body+Icon_1.png";
import Icon2 from "../../assets/images/True+Merge+Body+Icon_2.png";
import Icon3 from "../../assets/images/True+Merge+Body+Icon_3.png";

const ExperienceSection: React.FC = () => {
  return (
    <section className="bg-white py-16 px-8 space-y-12">
      {/* Top Section */}
      <div className="md:flex md:space-x-8 space-y-8 md:space-y-0 max-w-5xl mx-auto">
        {/* Top Left */}
        <div className="md:w-1/2 text-gray-800 text-2xl font-semibold">
          With 30 years of data hygiene experience for thousands of unique
          mailings, our team has tackled every mailing challenge.
        </div>

        {/* Top Right */}
        <div className="md:w-1/2 text-gray-800 text-2xl font-semibold">
          Through TrueMerge, we deduplicate, clean, and standardize your mailing
          lists quickly and accurately with data cleansing tools to help you
          create best-in-class campaigns without bottlenecks.
        </div>
      </div>

      {/* Bottom Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
        {/* Bottom Left */}
        <div className="flex flex-col items-center text-center space-y-4">
          <img
            src={Icon1}
            alt="24-Hour Turnarounds Icon"
            className="w-16 h-16"
          />
          <h3 className="text-xl font-semibold text-primary">
            24-Hour Turnarounds
          </h3>
          <p className="text-gray-700">Our average processing time</p>
        </div>

        {/* Bottom Middle */}
        <div className="flex flex-col items-center text-center space-y-4">
          <img
            src={Icon2}
            alt="Transparent Pricing Icon"
            className="w-16 h-16"
          />
          <h3 className="text-xl font-semibold text-primary">
            Transparent Pricing
          </h3>
          <p className="text-gray-700">No surprise or hidden fees</p>
        </div>

        {/* Bottom Right */}
        <div className="flex flex-col items-center text-center space-y-4">
          <img src={Icon3} alt="Secure Data Icon" className="w-16 h-16" />
          <h3 className="text-xl font-semibold text-primary">Secure Data</h3>
          <p className="text-gray-700">SOC 2 Compliance data protection</p>
        </div>
      </div>
    </section>
  );
};

export default ExperienceSection;
