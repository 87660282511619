// src/components/Header.tsx
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/Blue-Horse-Data_Logo_Primary_Navy.png";

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showServices, setShowServices] = useState(false);

  return (
    <header className="shadow relative z-20">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        {/* Logo */}
        <Link
          to="/"
          onClick={() => {
            setIsOpen(false);
            setShowServices(false);
          }}
        >
          <img src={Logo} alt="Blue Horse Data Logo" className="h-8 md:h-10" />
        </Link>

        {/* Hamburger Icon for Mobile */}
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="focus:outline-none text-primary"
          >
            {isOpen ? (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>
        </div>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-6 text-primary">
          <Link to="/" className="hover:text-primary-lightBlue">
            Home
          </Link>
          <div className="group relative">
            <span className="hover:text-primary-lightBlue cursor-pointer">
              Our Services
            </span>
            <div className="absolute left-0 top-full mt-2 w-48 bg-primary-white shadow-lg rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <ul className="flex flex-col py-2 text-primary">
                <li>
                  <Link
                    to="/services/truemerge"
                    className="block px-4 py-2 hover:bg-primary-lightBlue hover:text-white"
                  >
                    TrueMerge
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/rapidpost"
                    className="block px-4 py-2 hover:bg-primary-lightBlue hover:text-white"
                  >
                    RapidPost
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/datastable"
                    className="block px-4 py-2 hover:bg-primary-lightBlue hover:text-white"
                  >
                    DataStable
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <Link to="/contact" className="hover:text-primary-lightBlue">
            Contact
          </Link>
        </nav>
      </div>

      {/* Mobile Navigation Overlay */}
      {isOpen && (
        <div className="fixed inset-0 bg-primary-lightBlue flex flex-col items-center justify-center text-white z-40">
          {/* Logo and Close Icon */}
          <div className="absolute top-4 left-4 flex items-center justify-between w-full px-8 z-50">
            <Link
              to="/"
              onClick={() => {
                setIsOpen(false);
                setShowServices(false);
              }}
            >
              <img src={Logo} alt="Blue Horse Data Logo" className="h-10" />
            </Link>
            <button
              onClick={() => setIsOpen(false)}
              className="focus:outline-none z-50"
            >
              <svg
                className="w-8 h-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {/* Navigation Links with Transition */}
          <nav className="flex flex-col space-y-8 text-2xl font-semibold">
            <ul
              className={`absolute inset-0 flex flex-col items-center justify-center space-y-8 text-2xl font-semibold text-white transition-transform duration-500 z-40 ${
                showServices
                  ? "opacity-0 -translate-x-full"
                  : "opacity-100 translate-x-0"
              }`}
            >
              <li>
                <Link
                  to="/"
                  onClick={() => {
                    setIsOpen(false);
                    setShowServices(false);
                  }}
                  className="hover:text-primary-background"
                >
                  Home
                </Link>
              </li>
              <li>
                <button
                  onClick={() => setShowServices(true)}
                  className="hover:text-primary-background flex items-center"
                >
                  Our Services <span className="ml-2">›</span>
                </button>
              </li>
              <li>
                <Link
                  to="/contact"
                  onClick={() => {
                    setIsOpen(false);
                    setShowServices(false);
                  }}
                  className="hover:text-primary-background"
                >
                  Contact
                </Link>
              </li>
            </ul>

            {/* Services Submenu with Back Button */}
            <ul
              className={`absolute inset-0 flex flex-col items-center justify-center space-y-8 text-2xl font-semibold text-white transition-transform duration-500 z-40 ${
                showServices
                  ? "opacity-100 translate-x-0"
                  : "opacity-0 translate-x-full"
              }`}
            >
              <li>
                <button
                  onClick={() => setShowServices(false)}
                  className="hover:text-primary-background flex items-center"
                >
                  <span className="text-2xl mr-2">‹</span> Back
                </button>
              </li>
              <li>
                <Link
                  to="/services/truemerge"
                  onClick={() => {
                    setIsOpen(false);
                    setShowServices(false);
                  }}
                  className="hover:text-primary-background"
                >
                  TrueMerge
                </Link>
              </li>
              <li>
                <Link
                  to="/services/rapidpost"
                  onClick={() => {
                    setIsOpen(false);
                    setShowServices(false);
                  }}
                  className="hover:text-primary-background"
                >
                  RapidPost
                </Link>
              </li>
              <li>
                <Link
                  to="/services/datastable"
                  onClick={() => {
                    setIsOpen(false);
                    setShowServices(false);
                  }}
                  className="hover:text-primary-background"
                >
                  DataStable
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
