// src/views/home/TakeBackReinsSection.tsx
import React from "react";
import ArrowRedIcon from "../../assets/images/Arrow+Red.png";
import BackgroundImage from "../../assets/images/Older-Man-Reading.jpg";

const TakeBackReinsSection: React.FC = () => {
  return (
    <section
      className="bg-cover bg-center py-32" // Increased padding to make the section taller
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <div className="max-w-7xl mx-auto px-8 flex justify-end">
        {/* Informational Box */}
        <div className="bg-white rounded-lg p-8 md:p-12 w-full md:w-2/3 lg:w-1/2 shadow-lg">
          <h2 className="text-2xl md:text-3xl font-semibold text-primary mb-4">
            Take Back the Reins of Your Direct Mail Data
          </h2>
          <hr className="border-t-1 border-primary-accent mb-6" />

          <p className="text-gray-700 mb-4">
            Whether you’re a marketing leader looking to elevate the
            effectiveness of your direct mail campaigns or an agency hoping to
            speed up turnaround times, Blue Horse Data is the fast and reliable
            direct mail data partner you need to reach your goals.
          </p>
          <p className="text-gray-700 mb-8">
            We offer a range of services to help you mail with confidence.
          </p>

          {/* Arrow Icon */}
          <div className="flex justify-center">
            <img src={ArrowRedIcon} alt="Red Arrow" className="w-8 h-auto" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TakeBackReinsSection;
