// src/views/home/DirectMailChallengesSection.tsx
import React from "react";
import FrameIcon from "../../assets/images/Frame-552.png";
import ChartDownIcon from "../../assets/images/Chart-Down.png";
import GearCautionIcon from "../../assets/images/Gear-Cation.png";
import ThumbsDownIcon from "../../assets/images/Thumbs-Down.png";

const DirectMailChallengesSection: React.FC = () => {
  return (
    <section className="bg-gray-50 py-16 px-8">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12">
        {/* Left Column: Title and Information */}
        <div>
          <h2 className="text-3xl font-semibold text-primary mb-6">
            Direct Mailings Should Help Grow Your Business — Not Drain It
          </h2>
          <p className="text-gray-700 mb-4">
            Imagine pouring countless hours and dollars into crafting the
            perfect direct mail campaign only to have it immediately discarded
            because of addressee mistakes — or worse, never reach customers at
            all.
          </p>
          <p className="text-gray-700">
            Without a fast and accurate way to verify and clean addresses or see
            customer data, brands end up running into the same direct mail
            challenges:
          </p>
        </div>

        {/* Right Column: Icons with Labels */}
        <div className="grid grid-cols-2 gap-8">
          {/* Icon 1 */}
          <div className="text-center flex flex-col items-center space-y-4">
            <img
              src={FrameIcon}
              alt="Wasted Time & Resources"
              className="w-20 h-auto"
            />
            <p className="text-lg font-medium text-gray-800">
              Wasted Time & Resources
            </p>
          </div>

          {/* Icon 2 */}
          <div className="text-center flex flex-col items-center space-y-4">
            <img
              src={ChartDownIcon}
              alt="Lost Revenue"
              className="w-20 h-auto"
            />
            <p className="text-lg font-medium text-gray-800">Lost Revenue</p>
          </div>

          {/* Icon 3 */}
          <div className="text-center flex flex-col items-center space-y-4">
            <img
              src={GearCautionIcon}
              alt="Ineffective Campaigns"
              className="w-20 h-auto"
            />
            <p className="text-lg font-medium text-gray-800">
              Ineffective Campaigns
            </p>
          </div>

          {/* Icon 4 */}
          <div className="text-center flex flex-col items-center space-y-4">
            <img
              src={ThumbsDownIcon}
              alt="Damaged Customer Relationships"
              className="w-20 h-auto"
            />
            <p className="text-lg font-medium text-gray-800">
              Damaged Customer Relationships
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DirectMailChallengesSection;
