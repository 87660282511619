// src/components/CustomerBenefitsSection.tsx
import * as React from "react";
import Icon1 from "../../assets/images/Time+Saved+Weekly.png";
import Icon2 from "../../assets/images/Time+Saved+Weekly (1).png";
import Icon3 from "../../assets/images/Better+Timeline+Delivery.png";
import { Link } from "react-router-dom";

const CustomerBenefitsSection: React.FC = () => {
  return (
    <section className="bg-gray-100 py-24 px-10">
      <div className="max-w-7xl mx-auto text-center space-y-12">
        {/* Section Heading */}
        <h2 className="text-4xl md:text-5xl font-semibold text-primary">
          Keep Customers Happy & Deliverables On Time
        </h2>
        <p className="text-2xl text-gray-700 max-w-3xl mx-auto">
          Customers who choose RapidPost experience outcomes like:
        </p>

        {/* Benefit Columns */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-16 mt-12">
          {/* Column 1: Reduction in Spending */}
          <div className="flex flex-col items-center text-center space-y-6">
            <img
              src={Icon1}
              alt="Reduction in Spending Icon"
              className="w-20 h-20"
            />
            <h3 className="text-2xl font-semibold text-primary">
              Reduction in Spending
            </h3>
            <p className="text-lg text-gray-700 max-w-md">
              With fast and accurate CASS/NCOA processing, you won’t need to pay
              third party vendors for expedited timelines or updated tables.
            </p>
          </div>

          {/* Column 2: Time Saved Weekly */}
          <div className="flex flex-col items-center text-center space-y-6">
            <img
              src={Icon2}
              alt="Time Saved Weekly Icon"
              className="w-20 h-20"
            />
            <h3 className="text-2xl font-semibold text-primary">
              Time Saved Weekly
            </h3>
            <p className="text-lg text-gray-700 max-w-md">
              Instead of idle time waiting for team members to complete their
              parts of the process, you can move with agility and upload records
              directly to your API for results in minutes.
            </p>
          </div>

          {/* Column 3: Better Timeline Delivery */}
          <div className="flex flex-col items-center text-center space-y-6">
            <img
              src={Icon3}
              alt="Better Timeline Delivery Icon"
              className="w-20 h-20"
            />
            <h3 className="text-2xl font-semibold text-primary">
              Better Timeline Delivery
            </h3>
            <p className="text-lg text-gray-700 max-w-md">
              Execute CASS/NCOA processing faster than competitors and become
              known as the business customers can count on to deliver quality
              work on time, every time.
            </p>
          </div>
        </div>

        <hr className="border-t-2 border-gray-300 w-full my-20" />

        {/* Call to Action */}
        <div className="space-y-8">
          <h3 className="text-4xl md:text-5xl font-semibold text-primary">
            Ready To Get Started With TrueMerge?
          </h3>
          <p className="text-2xl text-gray-700 max-w-3xl mx-auto">
            Shoot us an email or set up a call with a member of our team today
            to learn more about how we can help you accelerate your merge/purge
            process with TrueMerge.
          </p>

          {/* Let's Talk Button */}
          <Link
            to="/contact"
            className="bg-primary-accent text-white font-semibold py-5 px-12 rounded-lg hover:bg-white hover:text-primary transition-colors inline-block mt-8 text-xl"
          >
            Let's Talk
          </Link>
        </div>
      </div>
    </section>
  );
};

export default CustomerBenefitsSection;
