// src/views/home/ExpectationsSection.tsx
import React, { useState } from "react";
import ConferenceRoomImage from "../../assets/images/Conference-Room.jpg";

const accordionData = [
  {
    title: "Speed",
    content:
      "Tight deadline? No problem. We consider ourselves bottleneck breakers — turning deliverables around in days, not weeks, to keep you on track to meet your deadlines.",
  },
  {
    title: "Accuracy",
    content:
      "Our team has spent the last 15 years testing, iterating, and perfecting our direct mail services to make sure we provide you with error-free, up-to-date data.",
  },
  {
    title: "Security",
    content:
      "Your data security is our priority. We’ve built our reputation on creating services and software that ensure safe and accurate data.",
  },
];

const ExpectationsSection: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<null | number>(null);

  const toggleAccordion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="bg-white py-24 px-12">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-16 items-start">
        {/* Left Column - Image */}
        <img
          src={ConferenceRoomImage}
          alt="Blue Horse Data Team"
          className="rounded-lg shadow-lg w-full"
        />

        {/* Right Column - Title and Accordion */}
        <div>
          <h2 className="text-3xl font-semibold text-primary mb-8">
            What You Can Expect From the Blue Horse Data Team:
          </h2>

          {/* Accordion */}
          <div className="border-t border-b border-gray-300 relative">
            {accordionData.map((item, index) => (
              <div
                key={index}
                className="border-b border-gray-300 overflow-hidden"
              >
                <button
                  onClick={() => toggleAccordion(index)}
                  className="w-full flex justify-between items-center py-6 text-left font-medium text-primary text-xl"
                >
                  {item.title}
                  <span className="text-xl">
                    {openIndex === index ? "−" : "+"}
                  </span>
                </button>
                <div
                  className={`transition-[max-height] duration-300 ease-in-out ${
                    openIndex === index ? "max-h-48" : "max-h-0"
                  }`}
                >
                  <p className="text-gray-700 py-4 px-6 text-lg leading-relaxed">
                    {item.content}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExpectationsSection;
