// src/components/ImageSection.tsx
import React from "react";
import BackgroundImage from "../../assets/images/GettyImages-1489413775_Edited_Squooshed.jpg";

const ImageSection: React.FC = () => {
  return (
    <section
      className="bg-cover bg-center py-20 md:py-32 px-8 flex items-center justify-start"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <div className="bg-primary p-8 md:p-10 rounded-lg w-full max-w-xs md:max-w-sm lg:max-w-md text-white ml-4 md:ml-16 lg:ml-24 space-y-4">
        {/* Title */}
        <h2 className="text-3xl md:text-4xl">
          Create Spotless Mailing Lists in a Matter of Hours
        </h2>

        {/* Accent-colored horizontal line */}
        <hr className="border-t-2 border-primary-accent w-3/4" />

        {/* Paragraph */}
        <p className="text-base md:text-lg">
          TrueMerge is your merge/purge solution to eliminate wasteful spending,
          enhance customer relationships, and streamline your direct mail
          efforts.
        </p>
      </div>
    </section>
  );
};

export default ImageSection;
