// src/views/Contact.tsx
import React, { useState } from "react";
import ContactImage from "../../assets/images/Matt-Whipple_Headshot-2024.jpg";

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
  });
  const [status, setStatus] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus("Sending...");
    setShowSuccessMessage(false); // Reset success message when submitting

    try {
      const response = await fetch("/api/contact", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatus("");
        setShowSuccessMessage(true); // Show success message on successful submission
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          subject: "",
          message: "",
        });
      } else {
        setStatus("Failed to send email.");
      }
    } catch (error) {
      console.error("Error:", error);
      setStatus("Failed to send email.");
    }
  };

  return (
    <section className="bg-gray-50 py-20 px-8 md:px-16 lg:px-32 space-y-16">
      {/* Top Row: Image, Title, and Caption */}
      <div className="flex flex-col md:flex-row items-center space-y-8 md:space-y-0 md:space-x-8">
        <div className="w-full md:w-1/3 flex justify-center md:justify-end">
          <img
            src={ContactImage}
            alt="Matt Whipple Headshot"
            className="rounded-lg object-cover w-64 h-64 md:w-56 md:h-56 lg:w-72 lg:h-72"
          />
        </div>
        <div className="text-center md:text-left space-y-4 max-w-lg">
          <h1 className="text-5xl md:text-6xl font-semibold text-primary">
            Let’s Talk
          </h1>
          <p className="text-xl md:text-2xl text-gray-700">
            Hi, I’m Matt. I love connecting with businesses to hear more about
            their direct mail needs and help them find solutions to the
            challenges holding them back.
          </p>
        </div>
      </div>

      {/* Divider with "Shoot me an email" Section */}
      <div className="flex items-center my-8 space-x-4">
        <hr className="flex-grow border-t border-gray-300" />
        <p className="text-xl md:text-2xl text-gray-700 px-4">
          <a
            href="mailto:matt.w@bluehorsedata.com"
            className="underline text-primary font-semibold"
            style={{
              textDecorationThickness: "2px",
              textUnderlineOffset: "3px",
            }}
          >
            Shoot me an email
          </a>{" "}
          or fill out the form below to discuss how Blue Horse Data can help you
          achieve your mailing goals.
        </p>
        <hr className="flex-grow border-t border-gray-300" />
      </div>

      {/* Contact Form Section */}
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl mx-auto">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label className="block text-gray-700 font-semibold text-lg">
              Name <span className="text-red-500">*</span>
            </label>
            <div className="flex space-x-4 mt-2">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                required
                className="w-1/2 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-lightBlue"
                value={formData.firstName}
                onChange={handleChange}
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                required
                className="w-1/2 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-lightBlue"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
          </div>

          <div>
            <label className="block text-gray-700 font-semibold text-lg">
              Email <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              required
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-lightBlue mt-2"
              value={formData.email}
              onChange={handleChange}
            />
          </div>

          <div>
            <label className="block text-gray-700 font-semibold text-lg">
              Subject <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              required
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-lightBlue mt-2"
              value={formData.subject}
              onChange={handleChange}
            />
          </div>

          <div>
            <label className="block text-gray-700 font-semibold text-lg">
              Message <span className="text-red-500">*</span>
            </label>
            <textarea
              name="message"
              placeholder="Your Message"
              required
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-lightBlue mt-2"
              rows={5}
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>

          <button
            type="submit"
            className="bg-primary-accent text-white font-semibold py-4 px-10 rounded-lg hover:bg-primary-lightBlue-dark transition-colors text-lg w-full md:w-auto"
          >
            Submit
          </button>
        </form>

        {/* Success Message */}
        {showSuccessMessage && (
          <div className="mt-8 p-4 bg-green-100 text-green-700 rounded-md text-center">
            Thank you for reaching out! Your form has been submitted, and you
            should hear back from us shortly.
          </div>
        )}

        {/* Status Message */}
        {status && <p className="text-center text-gray-600 mt-4">{status}</p>}
      </div>
    </section>
  );
};

export default Contact;
