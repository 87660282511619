// src/views/home/ContactSection.tsx
import React from "react";
import BackgroundImage from "../../assets/images/Man-On-Phone.jpg";
import { Link } from "react-router-dom";

const ContactSection: React.FC = () => {
  return (
    <section
      className="bg-cover bg-center py-20 md:py-48 px-8 text-white flex items-start md:items-center"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <div className="max-w-2xl mx-auto md:ml-16 lg:ml-24 text-left mt-10 md:mt-0 md:max-w-lg">
        {/* Title */}
        <h2 className="text-2xl md:text-5xl font-semibold mb-4 md:mb-6 max-w-xs md:max-w-md">
          Start Mailing With Impact Today
        </h2>

        {/* Paragraph */}
        <p className="text-base md:text-lg mb-6 md:mb-8 max-w-xs md:max-w-sm pr-4 md:pr-0">
          Reach out to our team to learn more about how Blue Horse Data can help
          you mail with confidence, reduce wasted resources, and make a greater
          impact on your customers.
        </p>

        {/* Button */}
        <Link
          to="/contact"
          className="bg-primary-accent text-white font-semibold py-3 px-8 rounded-lg hover:bg-white hover:text-primary transition-colors inline-block"
        >
          Let's Talk
        </Link>
      </div>
    </section>
  );
};

export default ContactSection;
