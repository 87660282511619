// src/views/home/SafeDataSection.tsx
import React from "react";
import AICPABadge from "../../assets/images/AICPA_SOC_Badge_NEW.png";
import ANAlogo from "../../assets/images/ANA-logo.png";
import NemoaLogo from "../../assets/images/Nemoa+Logo.png";

const SafeDataSection: React.FC = () => {
  return (
    <section className="bg-gray-200 py-24 px-8">
      <div className="max-w-5xl mx-auto bg-primary rounded-3xl p-16 text-center text-white shadow-lg">
        {/* Title */}
        <h2 className="text-4xl font-semibold mb-6">
          Your Data Is In Safe Hands
        </h2>

        {/* Subtitle */}
        <p className="text-2xl mb-4">Proud Members of:</p>
        <hr className="border-t-2 border-primary-lightBlue w-3/4 mx-auto mb-10" />

        {/* Logos */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 items-center justify-items-center">
          <img src={AICPABadge} alt="AICPA SOC Badge" className="w-40 h-auto" />
          <img src={ANAlogo} alt="ANA Logo" className="w-40 h-auto" />
          <img src={NemoaLogo} alt="Nemoa Logo" className="w-40 h-auto" />
        </div>
      </div>
    </section>
  );
};

export default SafeDataSection;
