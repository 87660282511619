// src/views/home/OurServicesSection.tsx
import React from "react";
import TrueMergeIcon from "../../assets/images/True-Merge.png";
import RapidPostIcon from "../../assets/images/Rapid-Post.png";
import DataStableIcon from "../../assets/images/Data-Stable.png";
import { Link } from "react-router-dom";

const OurServicesSection: React.FC = () => {
  return (
    <section className="bg-primary py-16">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-semibold text-white mb-4">
          Our Direct Mail Services
        </h2>
      </div>

      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-8">
        {/* Service 1 */}
        <div className="bg-customBlue-background/90 border-4 border-customBlue-border rounded-2xl p-8 text-white flex flex-col items-center max-w-xs mx-auto">
          <img
            src={TrueMergeIcon}
            alt="Merge/Purge Service"
            className="w-24 h-auto mb-4"
          />
          <h3 className="text-xl font-semibold mb-4 text-center">
            Merge/Purge Your Lists for Mailings You Can Trust
          </h3>
          <hr className="border-t border-primary-background w-12 mb-4" />
          <p className="text-center text-sm mb-6">
            Our merge/purge service focuses on speed, accuracy, and data
            security. Remove duplicates and clean and standardize your mailing
            data to produce effective mail campaigns on time and with maximum
            impact.
          </p>
          <Link
            to="/services/truemerge"
            className="bg-customBlue-background text-white font-semibold py-2 px-4 border border-white rounded-lg hover:bg-white hover:text-black transition-colors inline-block"
          >
            Explore TrueMerge
          </Link>
        </div>

        {/* Service 2 */}
        <div className="bg-customBlue-background/90 border-4 border-customBlue-border rounded-2xl p-8 text-white flex flex-col items-center max-w-xs mx-auto">
          <img
            src={RapidPostIcon}
            alt="CASS & NCOA Service"
            className="w-24 h-auto mb-4"
          />
          <h3 className="text-xl font-semibold mb-4 text-center">
            Mail With Precision Using CASS™ & NCOA via API
          </h3>
          <hr className="border-t border-primary-background w-12 mb-4" />
          <p className="text-center text-sm mb-6">
            Spend minutes, not days, on your Coding Accuracy Support System
            (CASS) and National Change of Address (NCOA) process with our
            RESTful API that helps standardize and update addresses, corrects
            errors, and makes sure mailings get to your intended recipients.
          </p>
          <Link
            to="/services/rapidpost"
            className="bg-customBlue-background text-white font-semibold py-2 px-4 border border-white rounded-lg hover:bg-white hover:text-black transition-colors inline-block"
          >
            Explore RapidPost
          </Link>
        </div>

        {/* Service 3 */}
        <div className="bg-customBlue-background/90 border-4 border-customBlue-border rounded-2xl p-8 text-white flex flex-col items-center max-w-xs mx-auto">
          <img
            src={DataStableIcon}
            alt="Data Storage & Analysis Service"
            className="w-24 h-auto mb-4"
          />
          <h3 className="text-xl font-semibold mb-4 text-center">
            Transform, Secure, & Standardize Your Customer Mailing Data
          </h3>
          <hr className="border-t border-primary-background w-12 mb-4" />
          <p className="text-center text-sm mb-6">
            Take your offline marketing to the next level with our direct mail
            database. Store all of your customer mailing data in one secure
            location, track direct mail performance, and create more targeted
            and effective campaigns with customer data, insights, and reports.
          </p>
          <Link
            to="/services/datastable"
            className="bg-customBlue-background text-white font-semibold py-2 px-4 border border-white rounded-lg hover:bg-white hover:text-black transition-colors inline-block"
          >
            Explore DataStable
          </Link>
        </div>
      </div>
    </section>
  );
};

export default OurServicesSection;
